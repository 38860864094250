// Translated
// Migrated
<template lang="pug">
b-modal(
  ref="charterBookingErrorModal"
  v-model="show"
  :hide-footer="true"
  :hide-header="true"
  :no-close-on-backdrop="true"
  :no-close-on-esc="noCloseOnEsc"
  body-class="no-padding"
  centered
  size="lg"
)
  .alert.mb-0
    .d-flex.align-items-center
      h3.mb-0
        slot(name="icon")
          fa.mr-3(icon="info-circle")
      .flex-fill
        slot(name="text")
          span(data-i18n="serverError") {{ $t('serverError') }}
      button.btn.btn-blue.ml-3(@click="clear")
        slot(name="btnText")
          span(data-i18n="ok") {{ $t('ok') }}
</template>

<script>
export default defineNuxtComponent({
  props: {
    errors: {
      required: true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      validator: v => true
    },

    noCloseOnEsc: {
      type: Boolean,
      default: true
    },

    closeSelf: {
      type: Boolean,
      default: true
    }
  },

  emits: ['clear'],

  data () {
    return {
      show: false
    }
  },

  watch: {
    errors (v) {
      this.show = !!v
    }
  },

  mounted () {
    this.show = !!this.errors
  },

  methods: {
    clear () {
      this.$emit('clear')

      if (this.closeSelf) {
        this.show = false
      }
    }
  }
})
</script>
